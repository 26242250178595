/**
 * Converts word to title case
 * @param {string} str
 */
const toTitleCase = (str) => str && str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
);

export default toTitleCase;
