import React, {
    createContext, useState, useContext, useMemo, useEffect
} from 'react';
import useLocalStorageSync, { localStorageGet, localStorageRemove, localStorageSet } from '@hooks/useLocalStorageSync';
import getSelectedModule from '../../utils/getSelectedModule';


const createRandomString = (num) => [...Array(num)].map(() => Math.random().toString(36)[2]).join('');

const BasketContext = createContext({});

/**
 * This provider handles all of our basket state
 * We use getSelectedModule method here to pull the current selected module from the url or stored state on page entry.
 */
export const BasketProvider = ({ ...props }) => {
    const storedBasket = useMemo(() => localStorageGet('basket'), []);
    const [basketSessionId, setBasketSessionId] = useState(localStorageGet('basket_session_id') || null);

    useEffect(() => {
        if (!basketSessionId) {
            const newSessionId = createRandomString(40);
            setBasketSessionId(newSessionId);
            localStorageSet('basket_session_id', newSessionId);
        }
    }, []);

    const [module, setModule] = useState(getSelectedModule(storedBasket));
    const [woodType, setWoodType] = useState(storedBasket ? storedBasket.woodType : 'softwood');
    const [fixingType, setFixingType] = useState(storedBasket ? storedBasket.fixingType : 'concrete');

    const calculatedPrice = useMemo(() => (module ? module.prices[woodType] : 9999), [module, woodType]);

    const value = {
        basket: {
            basketSessionId,
            module,
            woodType,
            fixingType,
            calculatedPrice
        },
        setModule,
        setWoodType,
        setFixingType,
        resetBasket: () => {
            setModule(undefined);
            setWoodType('softwood');
            setFixingType('concrete');
            localStorageRemove('basket');
        }
    };

    useLocalStorageSync('basket', value.basket);

    return <BasketContext.Provider {...props} value={value} />;
};

/** NOICE HOOK */
export const useBasketContext = () => useContext(BasketContext);
