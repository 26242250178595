
import queryString from 'query-string';
import modules from '@data/json/modules.json';

/**
 * We need to get the default module into state somehow and this requires some rules.
 * - 1 url and new user selection is always priority
 * - If some one goes to /shop without any query string, we need to try pull
 *   from local storage or redirect them to the module selector
 * @param {*} storedBasket
 */
const getSelectedModule = (storedBasket) => {
    // check the url for query parameters.
    const querystring = (typeof window !== 'undefined') ? queryString.parse(window.location.search) : {};

    // Check if module exists
    if (querystring.module) {
        return modules.find((m) => m.slug === querystring.module);
    }

    // check stored basket
    if (storedBasket) {
        return storedBasket.module;
    }

    if (typeof window !== 'undefined') {
        // if we dont have a module, force them to the url to get it
        window.location.href = '/shop';
    }

    return null;
};

export default getSelectedModule;
